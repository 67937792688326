<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div id="iiotDeployStepTwo">
    <the-nerve-table
      :id="'iiotDeployStepTwoDevicesDeploy'"
      :columns="nodesColumns"
      :params="params"
      :empty-state="$t('deployForm.workloadStep2.nodeList.emptyText')"
      :is-action-menu-enabled="false"
      :is-row-clickable="false"
      :is-check-box-column-without-select-all-enabled="true"
      store-module="nodes"
      custom-fetch-action="fetch_nodes_for_deploy"
      check-item-action="select_node"
      selected-items-getter="selectedNodesList"
      :class="{ 'wrapper-class': items.length >= 1 }"
      @params-changed="changedParams"
    >
      <template #additional-actions>
        <v-row v-resize="onResize" class="node-info">
          <v-col
            cols="3"
            lg="2"
            md="2"
            :class="{
              'd-flex justify-end align-center': !isMarginVisible,
              'd-flex justify-start align-center': isMarginVisible,
            }"
          >
            <label id="iiotDeployStepTwoNumberOfNodes" data-cy="iiotDeployStepTwoNumberOfNodes">
              {{ $t('deployForm.workloadStep2.nodes') }} {{ nodeCount }}
            </label>
          </v-col>
          <v-col
            cols="9"
            lg="3"
            md="4"
            sm="4"
            :class="{
              'd-flex justify-center align-center': !isMarginVisible,
              'd-flex justify-start align-center': isMarginVisible,
            }"
          >
            <label id="iiotDeployStepTwoSelectNodes" data-cy="iiotDeployStepTwoSelectNodes">
              {{ $t('deployForm.workloadStep2.selectedNodes') }}
              {{ selectedNodesList.length }}</label
            >
          </v-col>
        </v-row>
      </template>
      <template #additional-info>
        <div class="mt-8">
          <v-row>
            <div class="pl-7">
              <v-checkbox
                id="iiotDeployStepTwoSelectAllCheckbox"
                v-model="selectAll"
                data-cy="iiotDeployStepTwoSelectAllCheckbox"
                :label="$t('deployForm.workloadStep2.selectAll')"
                :disabled="!canAccess('UI_DEPLOY:DEPLOY') || nodeList.length === 0"
                @click="selectRemoveNodes"
              />
            </div>
          </v-row>
        </div>
      </template>
    </the-nerve-table>
  </div>
</template>

<script>
import TheNerveTable from 'nerve-ui-components/components/TheNerveTable.vue';

export default {
  components: {
    TheNerveTable,
  },
  props: {
    workload: {
      type: Object,
      default: () => ({ versionId: '', workloadId: '' }),
    },
  },
  data() {
    return {
      params: {
        page: 1,
        workload: this.workload,
      },
      selectAll: false,
      isMarginVisible: false,
    };
  },
  computed: {
    selectedNodesList() {
      return this.$store.getters['nodes/selectedNodesList'];
    },
    nodeList() {
      return this.$store.getters['nodes/list'];
    },
    nodesColumns() {
      return [
        {
          text: this.$t('nodes.list.name'),
          value: 'name',
          component: {
            sfc: () => import('@/components/nodes/node-list/NameColumn.vue'),
          },
        },
        {
          text: this.$t('deployForm.workloadStep2.nodeList.serialNumber'),
          value: 'serialNumber',
          sortable: false,
        },
        {
          text: this.$t('deployForm.workloadStep2.nodeList.nodeVersion'),
          value: 'currentFWVersion',
          sortable: false,
        },
      ];
    },
    items() {
      return this.$store.getters['nodes/list'];
    },
    nodeCount() {
      return this.$store.getters['nodes/count'];
    },
  },
  watch: {
    selectedNodesList() {
      this.isSelectedAll();
    },
    selectedVersion() {
      return this.selectedNodesList.length;
    },
    nodeList() {
      this.isSelectedAll();
    },
  },
  methods: {
    selectRemoveNodes() {
      // eslint-disable-next-line no-unused-expressions
      this.selectAll
        ? this.$store.dispatch('nodes/select_nodes_on_page')
        : this.$store.dispatch('nodes/deselect_nodes_on_page');
    },
    changedParams(parameters) {
      this.params = {
        ...parameters,
        selectedType: this.params.selectedType,
        disabled: this.params.disabled,
      };
    },
    isSelectedAll() {
      // eslint-disable-next-line max-len
      this.selectAll = this.nodeList.every((node) => !!this.selectedNodesList.find((slNode) => node.id === slNode.id));
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 1264;
    },
  },
};
</script>

<style lang="scss">
#iiotDeployStepTwo {
  .v-data-table__wrapper {
    max-height: 55vh;
    overflow-y: auto;
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin: 0;
    }
  }
}
.wrapper-class {
  .v-data-table__wrapper {
    padding-left: 50px;
  }
}
</style>
